<script>
/* eslint-disable vue/no-v-html */
</script>
<template>
  <b-card>
    <div class="col-lg-12 control-section mt-2 p-0">
      <div class="content-wrapper">
        <ejs-grid
          id="overviewgrid"
          ref="overviewgrid"
          locale="it-IT"
          :data-source="rowsComputed"
          :selection-settings="selectionSettings"
          :edit-settings="editSettings"
          :filter-settings="filterOptions"
          :show-column-chooser="true"
          :allow-selection="true"
          :allow-sorting="true"
          :allow-filtering="true"
          :allow-resizing="true"
          :enable-virtualization="false"
          :allow-row-drag-and-drop="false"
          height="300"
          :pdf-header-query-cell-info="pdfHeaderQueryCellInfo"
          :allow-pdf-export="true"
          :allow-excel-export="true"
          row-height="38"
          :enable-hover="false"
          :enable-header-focus="true"
          :load="load"
          :toolbar="toolbar"
          :toolbar-click="toolbarClick"
          :action-begin="actionTableBegin"
          :action-complete="actionTableComplete"
          :allow-grouping="true"
          :allow-paging="true"
          :page-settings="pageSettings"
          :context-menu-items="contextMenuItems"
          :context-menu-click="contextMenuClick"
          :data-bound="dataBound"
          :command-click="commandColumm"
        >
          <e-columns>
            <e-column
              type="checkbox"
              :allow-filtering="false"
              :allow-sorting="false"
              width="60"
            />
            <e-column
              field="id"
              :visible="false"
              header-text="ID"
              :is-primary-key="true"
              width="130"
            />
            <e-column
              field="nome"
              header-text="Nome"
              width="230"
              :filter="filter"
            />
            <e-column
              field="tipo"
              header-text="Tipo"
              width="200"
              :filter="filter"
              clip-mode="EllipsisWithTooltip"
            />
            <e-column
              field="ip"
              header-text="IP"
              width="170"
              :filter="filter"
              clip-mode="EllipsisWithTooltip"
              :allow-editing="false"
            />
            <e-column
              field="porta"
              header-text="Porta"
              width="100"
              :filter="filter"
            />
            <e-column
              field="descrizione"
              header-text="Descrizione"
              width="230"
              :filter="filter"
              :visible="false"
            />
            <e-column
              field="modello"
              header-text="Modello"
              width="100"
              :filter="filter"
              :visible="false"
            />
            <e-column
              field="codiceSeriale"
              header-text="Numero Seriale"
              width="100"
              :filter="filter"
              :visible="false"
            />
            <e-column
              field="codiceProduttore"
              header-text="Cod Produttore"
              width="100"
              :filter="filter"
              :visible="false"
            />
            <e-column
              field="codiceInterno"
              header-text="Cod Interno"
              width="100"
              :filter="filter"
              :visible="false"
            />
            <e-column
              field="Azioni"
              :lock-column="true"
              header-text="Azioni"
              width="240"
              :template="cTemplate"
              :allow-editing="false"
              :commands="commands"
              :allow-sorting="false"
              :allow-filtering="false"
            />
          </e-columns>
        </ejs-grid>
      </div>
    </div>
    <!-- <SettingsPdfExport
      :show="showModalSettingPdfExport"
      @pdf-export-confirm-close="closeModal"
    /> -->
    <sidebar-manage-device ref="sidebar-manage-device" />
  </b-card>
</template>

<script>
/* eslint-disable no-plusplus */
/* eslint-disable no-multi-str */
/* eslint-disable vue/no-side-effects-in-computed-properties */

import Vue from 'vue'
import {
  GridPlugin, Sort, Filter, Selection, PdfExport, ExcelExport, Toolbar, Edit, Page, ColumnChooser, ContextMenu, CommandColumn, Resize, Scroll,
} from '@syncfusion/ej2-vue-grids'
// import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns'
import Ripple from 'vue-ripple-directive'
import { italian } from '@/@core/grid-translation/it-IT'
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { BCard, BButton, BRow, BCol, VBToggle, BBadge } from 'bootstrap-vue'
import { getDispositivi } from '@/@core/api-service/devices/dispositivi'
import SidebarManageDevice from './details/SidebarManageDevice.vue'
setCulture('it-IT');
//Vue.use(DropDownListPlugin)
L10n.load(italian)
/* L10n.load({
    'it-IT': {
        'grid': {
            'EmptyRecord': 'Nessun record',
            'GroupDropArea': "Trascina qui l'intestazione di una colonna per raggruppare la colonna",
            'UnGroup': 'Clicca qui per separare',
            'EmptyDataSourceError': 'DataSource non deve essere vuoto al caricamento iniziale perché le colonne di dataSource si trovano nella griglia di colonne AutoGenerate',
            'Item': 'Item',
            'Items': 'Items',
            'Add': 'Aggiungi',
            'Edit': 'Modifica',
            'Cancel': 'Annulla',
            'Delete': 'Elimina',
            'Print': 'Stampa',
            'Pdfexport': 'Esporta PDF',
            'Excelexport': 'Esporta Excel',
            'Wordexport': 'Esporta Word',
            'Csvexport': 'Esporta CSV',
            'Columnchooser': 'Colonne',
            'Save': 'Salva',
        },
        'pager':{
            'currentPageInfo': '{0} di {1} Pagine',
            'totalItemsInfo': '({0} Records)',
            'firstPageTooltip': 'Prima Pagina',
            'lastPageTooltip': 'Ultima Pagina',
            'nextPageTooltip': 'Pagina Successiva',
            'previousPageTooltip': 'Pagina Precedente',
            'nextPagerTooltip': 'Pagine Seguenti',
            'previousPagerTooltip': 'Pagine Precedenti'
        }
    }
});
 */
Vue.use(GridPlugin)

export default Vue.extend({
  components: {
    BCard,
    BButton,
    // SettingsPdfExport,
    BRow,
    BCol,
    SidebarManageDevice,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data: () => ({
          cTemplate: function () {
          return { template : Vue.component('columnTemplate',{
             template: `<div class="image">
                    <img :src="image" :alt="altImage"/>
                </div>`,
                data: function() {
                    return {
                        data: {}
                    }
                },
                computed: {
                    image: function() {
                        return './' + this.data.EmployeeID + '.png'
                    },
                    altImage: function() {
                        return this.data.EmployeeID
                    }
                }
          })
          }
          },
    warehouse: {},
    commands: [
        { type: 'Elimina', id: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
        { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
        { type: 'Dettagli', id: 'Dettagli', target: '.e-column', buttonOption: { iconCss: 'e-icons e-folder-open', cssClass: 'e-flat' } }],
    contextMenuItems: ['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending',
      'Copy', 'Save',
      'PdfExport', 'ExcelExport', 'CsvExport', 'FirstPage', 'PrevPage',
      'LastPage', 'NextPage',  'Group', 'Ungroup',
      {text: 'Dettagli', target: '.e-content', id: 'Dettagli', iconCss: 'e-icons e-folder-open' },
      {
        text: 'Elimina', tooltipText: 'Delete', iconCss: 'e-icons e-delete', id: 'DeleteCustom',
      },
    ],
    pageSettings: { pageSize: 25, pageSizes: [25,50,100,200], pageCount: 5 },
    showModalSettingPdfExport: false,
    editSettings: { allowFiltering: true, allowEditing: false, allowAdding: true, allowDeleting: true, mode: 'Normal' },
    toolbar: [
      {
        text: 'Aggiungi', tooltipText: 'Aggiungi', prefixIcon: 'e-plus', id: 'Aggiungi',
      },
      {
        text: 'Elimina', tooltipText: 'Elimina', prefixIcon: 'e-delete', id: 'Elimina',
      },
     'ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Print'],
    toolbarOptions: ['ExcelExport'],
    ddData: [{ value: 1000, text: '1,000 Rows and 11 Columns' }, { value: 10000, text: '10,000 Rows and 11 Columns' }],
    ddValue: 10000,
    stTime: null,
    loadTime: null,
    dReady: false,
    isDataChanged: true,
    fields: { text: 'text', value: 'value' },
    rows: [],
    rows1: [
      {
        id: 1,
        nome: 'bilancia1',
        tipo: 'bilancia',
        descrizione: 'bilancia descrizione1',
        serialNumber: 'dasdasda',
        codiceProduttore: 'cod produttore 111',
        codiceInterno: '1',
        indirizzoIp: '192.168.0.1',
        porta: '443',
      },
      {
        id: 2,
        nome: 'bilancia1',
        tipo: 'Bilancia',
        descrizione: 'bilancia descrizione2',
        serialNumber: 'dasdasda',
        codiceProduttore: 'cod produttore 222',
        codiceInterno: '1',
        indirizzoIp: '192.168.0.2',
        porta: '442',
      },
      {
        id: 3,
        nome: 'bilancia1',
        tipo: 'Bilancia',
        descrizione: 'bilancia descrizione3',
        serialNumber: 'dasdasda',
        codiceProduttore: 'cod produttore 333',
        codiceInterno: '1',
        indirizzoIp: '192.168.0.3',
        porta: '443',
      },
      {
        id: 4,
        nome: 'bilancia1',
        tipo: 'Bilancia',
        descrizione: 'bilancia descrizione4',
        serialNumber: 'dasdasda',
        codiceProduttore: 'cod produttore 444',
        codiceInterno: '1',
        indirizzoIp: '192.168.0.4',
        porta: '444',
      },
    ],
    shopTemplate() {
      return {
        template: Vue.component('shopTemplate', {
          components: {
            BBadge,
          },
          data() { return { data: {}, inactive: 'Inactive' } },
          template: `<div id="" class="">
                          <b-badge
                            v-for="(pv, index) in data.reparti"
                            :key="index"
                            class="mr-1"
                          >{{ pv.nome }}
                          </b-badge>
                        </div>`,
        }),
      }
    },
    filterOptions: {
      type: 'Menu',
    },
    filter: {
      type: 'CheckBox',
    },
    selectionSettings: { persistSelection: false, type: 'Multiple', checkboxOnly: true },
  }),
  async mounted() {
    let dataSource = []
    const plu = await getDispositivi().then(res => {
      // console.log('getDispositivi', res)
      if (res.data.esito === 'OK') {
        // console.log('sono su ok getPlui')
        this.rows = [...res.data.dispositivi].map(el => ({ id: el.id, idReparto: el.id_reparto, modello: el.modello, descrizione: el.descrizione, nome: el.nome, codiceInterno: el.codice_interno, codiceSeriale: el.codice_seriale, codiceProduttore: el.codice_produttore, tipo: el.tipo, ip: el.ip, porta: el.porta }))
        // this.rows = [...res.data.plu]
        this.$store.dispatch('dispositivi/setDispositivi', this.rows)

      }
    }).catch(e => e)
    /* getListini().then(res => {
      console.log('res listini', res)
      if (res.data.esito === 'OK') {
        this.rows.push(...res.data.listini)
      }
    }) */
  },
  computed: {
    rowsComputed() {
      // console.log('ciao')
      return this.$store.getters['dispositivi/getDispositivi']
    },
  },
  methods: {
    dataBound() {
      // console.log(this.$refs.overviewgrid)
      this.$refs.overviewgrid.autoFitColumns(['Azioni' ]);
    },
    commandColumm(args) {
      if (args.commandColumn.type === 'Dettagli') {
        this.$refs['sidebar-manage-device'].setTitle('Modifica Device')
          const data = args.rowData
          // console.log('commandColumm', data)
          this.$store.dispatch('dispositivi/setDispSelected', { ...data })
          // console.log(this.$store.getters['dispositivi/getDispSelected'])
          this.$refs['sidebar-manage-device'].setDevice(args.rowData)
          this.$refs['sidebar-manage-device'].openSidebar()
        }
    },
    contextMenuClick(args) {
        // console.log('contextMenuClick', args)
        if (args.item.id === 'Dettagli') {
          this.$refs['sidebar-manage-device'].setTitle('Modifica Device')
          const data = args.rowInfo.rowData
          this.$store.dispatch('dispositivi/setDispSelected', { ...data })
          this.$refs['sidebar-manage-device'].setDevice(args.rowInfo.rowData)
          this.$refs['sidebar-manage-device'].openSidebar()
        }
    },
    closeModal() {
      this.showModalSettingPdfExport = false
    },
    actionTableBegin() {
      // console.log(action)
    },
    actionTableComplete() {
      // console.log(row)
    },
    pdfHeaderQueryCellInfo() {
      // args.cell.row.pdfGrid.repeatHeader = true;
    },
     toolbarClick(args) {
        // console.log(args)
        if (args.item.id === 'Dettagli') {
          alert("Custom Toolbar Click...");
        }
        if (args.item.id === 'Aggiungi') {
          this.$refs['sidebar-manage-device'].setTitle('Aggiungi Device')
          const data = {
            id: 0,
            nome: '',
            codiceInterno: '',
            descrzione: '',
            codiceSeriale: '',
            codiceProduttore: '',
            tipo: '',
            modello: '',
            idReparto: '',
            porta: '',
            ip: '',
          }
          this.$store.dispatch('dispositivi/setDispSelected', data)
          this.$refs['sidebar-manage-device'].openSidebar()
        }
        if (args.item.id === 'overviewgrid_pdfexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
          this.showModalSettingPdfExport = true
            const exportProperties = {
                //you can customize the name as per your requirement
                fileName: 'prova.pdf',
             }
            // this.$bvModal.show('setting-pdf-export-modal')
            this.$refs.overviewgrid.pdfExport(exportProperties)
            // this.$refs.overviewgrid.pdfExport(exportProperties)
        }
        if (args.item.id === 'overviewgrid_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
            this.$refs.overviewgrid.excelExport()
        }
        if (args.item.id === 'overviewgrid_csvexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
            this.$refs.overviewgrid.csvExport()
        }
     },
    valueChange(args) {
      this.$refs.dropdown.$el.ej2_instances[0].closePopup()
      this.$refs.overviewgrid.showSpinner()
      this.isDataChanged = true
      this.stTime = null
      const contentElement = this.$refs.overviewgrid.ej2Instances.contentModule.content
      this.$refs.overviewgrid.ej2Instances.pageSettings.currentPage = 1
      contentElement.scrollLeft = 0
      contentElement.scrollTop = 0
      console.log(this.$refs.overviewgrid.ej2Instances.pageSettings.currentPage)
      this.stTime = performance.now()
      this.ddValue = args.value
      this.$refs.overviewgrid.hideSpinner()
    },
    load() {
      const proxy = this
      this.$refs.overviewgrid.$el.ej2_instances[0].on('data-ready', () => {
        proxy.dReady = true
      })
      this.$refs.overviewgrid.$el.addEventListener('DOMSubtreeModified', () => {
        if (proxy.dReady && proxy.stTime && proxy.isDataChanged) {
          const e = performance.now() - proxy.stTime
          proxy.loadTime = `Load Time: <b>${e.toFixed(0)}</b><b>ms</b>`
          proxy.stTime = null
          proxy.dReady = false
          proxy.isDataChanged = false
          // proxy.$refs.msgelement.classList.remove('e-hide')
        }
      })
    },
    /* addFlowWarehouse(warehouse, title) {
      this.$refs['sidebar-manage-device'].setTitle(title)
      this.$refs['sidebar-manage-device'].openSidebar()

    }, */
  },
  provide: {
    grid: [Toolbar, PdfExport, ExcelExport, Filter, Selection, Sort, Edit, Page, ColumnChooser, ContextMenu, CommandColumn, Resize, Scroll, Filter],
  },

})

</script>
<style scoped>
/* @import "../../../../../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap.css"; */
</style>
