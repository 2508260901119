/* eslint-disable  */
import axios from 'axios'

export function getDispositivi() {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, {},
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Dev-Dispositivi',
          metodo: 'getDispositivi',
          ACL: '',
        },
      })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}
export function setDispositivi(dispositivi) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, dispositivi,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Dev-Dispositivi',
          metodo: 'setDispositivi',
          ACL: '',
        },
      })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}

export function deleteDispositivi(dispositivi) {
    // console.log('dentro reparti', reparti)
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_URL_API_SERVER, dispositivi,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Log-Reparto',
            metodo: 'deleteReparti',
            ACL: '',
          },
        })
        .then(res => resolve(res))
        .catch(error => reject(error))
    })
  }
  
  