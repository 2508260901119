<template>
  <div>
    <validation-observer ref="validation-observer-device-connection-modal-event-handler">
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <validation-provider
            #default="{ errors }"
            name="IP"
            :rules="{ required: true, regex: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/ }"
          >
            <b-form-group
              label="Indirizzo IP"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="dispositivoSelezionato.ip"
                  placeholder="ip"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <validation-provider
            #default="{ errors }"
            name="porta"
            rules="required"
          >
            <b-form-group
              label="porta"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="dispositivoSelezionato.porta"
                  placeholder="porta"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="primary"
            @click="submitConnessione"
          >
            Salva
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BFormGroup, BInputGroup, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import { setDispositivi } from '@/@core/api-service/devices/dispositivi'

export default {
  components: {
    BRow, BCol, BFormGroup, BInputGroup, BFormInput, ValidationProvider, ValidationObserver, BButton,
  },
  data() {
    return {
      required,
      locale: 'it',
      connesione: {
        ip: '',
        porta: '',
        descrzione: '',
      },
    }
  },
  computed: {
    dispositivoSelezionato() {
      return this.$store.getters['dispositivi/getDispSelected']
    },
  },
  mounted() {
    localize(this.locale)
  },
  methods: {
    submitConnessione() {
      this.$refs['validation-observer-device-connection-modal-event-handler'].validate()
        .then(success => {
          if (success) {
            const dataToSend = this.dispositivoSelezionato
            // console.log(dataToSend)
            setDispositivi([{ ...dataToSend }]).then(res => {
              if (res.data.esito === 'OK') {
                // console.log('esito', res)
                const data = { ...res.data.dispositivi }
                const data1 = {
                  id: data.id, idReparto: data.id_reparto, modello: data.modello, descrizione: data.descrizione, nome: data.nome, codiceInterno: data.codice_interno, codiceSeriale: data.codice_seriale, codiceProduttore: data.codice_produttore, tipo: data.tipo, ip: data.ip, porta: data.porta,
                }
                this.$store.dispatch('dispositivi/updateDispositivo', data1)
                // this.$emit('update-dev', data1)
                this.$emit('close-sidebar')
              }
            }).catch(e => e)
            // console.log('submited general')
          }
        })
        .catch(e => e)
    },
  },
}
</script>

<style>

</style>
