var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"validation-observer-device-general-modal-event-handler"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"nome"},model:{value:(_vm.dispositivoSelezionato.nome),callback:function ($$v) {_vm.$set(_vm.dispositivoSelezionato, "nome", $$v)},expression:"dispositivoSelezionato.nome"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"tipo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"tipo"},model:{value:(_vm.dispositivoSelezionato.tipo),callback:function ($$v) {_vm.$set(_vm.dispositivoSelezionato, "tipo", $$v)},expression:"dispositivoSelezionato.tipo"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"codice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Codice","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"codice"},model:{value:(_vm.dispositivoSelezionato.codiceInterno),callback:function ($$v) {_vm.$set(_vm.dispositivoSelezionato, "codiceInterno", $$v)},expression:"dispositivoSelezionato.codiceInterno"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Codice Seriale","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Codice Seriale","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"codice seriale"},model:{value:(_vm.dispositivoSelezionato.codiceSeriale),callback:function ($$v) {_vm.$set(_vm.dispositivoSelezionato, "codiceSeriale", $$v)},expression:"dispositivoSelezionato.codiceSeriale"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"modello","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Modello","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"codice seriale"},model:{value:(_vm.dispositivoSelezionato.modello),callback:function ($$v) {_vm.$set(_vm.dispositivoSelezionato, "modello", $$v)},expression:"dispositivoSelezionato.modello"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"codice produttore","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Codice Produttore","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"codice produttore"},model:{value:(_vm.dispositivoSelezionato.codiceProduttore),callback:function ($$v) {_vm.$set(_vm.dispositivoSelezionato, "codiceProduttore", $$v)},expression:"dispositivoSelezionato.codiceProduttore"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Reparto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Reparto Associato","label-class":"font-weight-bolder"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nome","clearable":false,"filterable":"","placeholder":"reparto associato","reduce":function (valore) { return valore.id; },"options":_vm.repartiOptions},on:{"open":_vm.getRepartiOptions},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Nessuna Reparto Trovato.")])]}}],null,true),model:{value:(_vm.dispositivoSelezionato.idReparto),callback:function ($$v) {_vm.$set(_vm.dispositivoSelezionato, "idReparto", $$v)},expression:"dispositivoSelezionato.idReparto"}}),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"descrizione","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descrizione","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-textarea',{attrs:{"placeholder":"descrizione","rows":"2"},model:{value:(_vm.dispositivoSelezionato.descrizione),callback:function ($$v) {_vm.$set(_vm.dispositivoSelezionato, "descrizione", $$v)},expression:"dispositivoSelezionato.descrizione"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(_vm.dispositivoSelezionato.id === 0)?_c('div',[_c('b-col',[_c('validation-provider',{attrs:{"name":"IP","rules":{ required: true, regex: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Indirizzo IP","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"ip"},model:{value:(_vm.dispositivoSelezionato.ip),callback:function ($$v) {_vm.$set(_vm.dispositivoSelezionato, "ip", $$v)},expression:"dispositivoSelezionato.ip"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1042159724)}),_c('validation-provider',{attrs:{"name":"porta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"porta","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"porta"},model:{value:(_vm.dispositivoSelezionato.porta),callback:function ($$v) {_vm.$set(_vm.dispositivoSelezionato, "porta", $$v)},expression:"dispositivoSelezionato.porta"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,256336179)})],1)],1):_vm._e(),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submitGeneral}},[_vm._v(" Salva ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }