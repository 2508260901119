<template>
  <div>
    <validation-observer ref="validation-observer-device-general-modal-event-handler">
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <validation-provider
            #default="{ errors }"
            name="nome"
            rules="required"
          >
            <b-form-group
              label="Nome"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="dispositivoSelezionato.nome"
                  placeholder="nome"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <validation-provider
            #default="{ errors }"
            name="tipo"
            rules="required"
          >
            <b-form-group
              label="Tipo"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="dispositivoSelezionato.tipo"
                  placeholder="tipo"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <validation-provider
            #default="{ errors }"
            name="codice"
            rules="required"
          >
            <b-form-group
              label="Codice"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="dispositivoSelezionato.codiceInterno"
                  placeholder="codice"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <validation-provider
            #default="{ errors }"
            name="Codice Seriale"
            rules="required"
          >
            <b-form-group
              label="Codice Seriale"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="dispositivoSelezionato.codiceSeriale"
                  placeholder="codice seriale"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <validation-provider
            #default="{ errors }"
            name="modello"
            rules="required"
          >
            <b-form-group
              label="Modello"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="dispositivoSelezionato.modello"
                  placeholder="codice seriale"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <validation-provider
            #default="{ errors }"
            name="codice produttore"
            rules="required"
          >
            <b-form-group
              label="Codice Produttore"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="dispositivoSelezionato.codiceProduttore"
                  placeholder="codice produttore"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <validation-provider
            #default="{ errors }"
            name="Reparto"
            rules="required"
          >
            <b-form-group
              label="Reparto Associato"
              label-class="font-weight-bolder"
            >
              <v-select
                v-model="dispositivoSelezionato.idReparto"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="nome"
                :clearable="false"
                filterable
                placeholder="reparto associato"
                :reduce="valore => valore.id"
                :options="repartiOptions"
                @open="getRepartiOptions"
              >
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #no-options="{ search, searching, loading }">
                  <div>Nessuna Reparto Trovato.</div>
                </template>
              </v-select>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
        >
          <validation-provider
            #default="{ errors }"
            name="descrizione"
            rules="required"
          >
            <b-form-group
              label="Descrizione"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-textarea
                  v-model="dispositivoSelezionato.descrizione"
                  placeholder="descrizione"
                  rows="2"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <div
          v-if="dispositivoSelezionato.id === 0"
        >
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="IP"
              :rules="{ required: true, regex: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/ }"
            >
              <b-form-group
                label="Indirizzo IP"
                label-class="font-weight-bolder"
              >
                <b-input-group>
                  <b-form-input
                    v-model="dispositivoSelezionato.ip"
                    placeholder="ip"
                  />
                </b-input-group>
                <small class="text-danger mt-25">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name="porta"
              rules="required"
            >
              <b-form-group
                label="porta"
                label-class="font-weight-bolder"
              >
                <b-input-group>
                  <b-form-input
                    v-model="dispositivoSelezionato.porta"
                    placeholder="porta"
                  />
                </b-input-group>
                <small class="text-danger mt-25">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </div>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="primary"
            @click="submitGeneral"
          >
            Salva
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BFormGroup, BInputGroup, BFormInput, BRow, BCol, BButton, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { getReparti } from '@/@core/api-service/logistic/reparto'
import { setDispositivi } from '@/@core/api-service/devices/dispositivi'

export default {
  components: {
    BRow, BCol, BFormGroup, BInputGroup, BFormInput, ValidationProvider, ValidationObserver, BButton, BFormTextarea, vSelect,
  },
  data() {
    return {
      required,
      locale: 'it',
      generale: {
        nome: '',
        codiceInterno: '',
        descrzione: '',
        codiceSeriale: '',
        codiceProduttore: '',
        tipo: '',
        modello: '',
        idReparto: '',
        ip: '',
        porta: '',
      },
      porta: '',
      ip: '',
      repartiOptions: [],
    }
  },
  computed: {
    dispositivoSelezionato() {
      return this.$store.getters['dispositivi/getDispSelected']
    },
  },
  mounted() {
    localize(this.locale)
  },
  methods: {
    getRepartiOptions() {
      getReparti().then(res => {
        if (res.data.esito === 'OK') {
          this.repartiOptions = [...res.data.reparti]
        }
      })
    },
    submitGeneral() {
      this.$refs['validation-observer-device-general-modal-event-handler'].validate()
        .then(success => {
          if (success) {
            const dataToSend = this.dispositivoSelezionato
            // console.log(dataToSend)
            setDispositivi([{ ...dataToSend }]).then(res => {
              if (res.data.esito === 'OK') {
                // console.log('esito', res)
                const data = { ...res.data.dispositivi }
                const data1 = {
                  id: data.id, idReparto: data.id_reparto, modello: data.modello, descrizione: data.descrizione, nome: data.nome, codiceInterno: data.codice_interno, codiceSeriale: data.codice_seriale, codiceProduttore: data.codice_produttore, tipo: data.tipo, ip: data.ip, porta: data.porta,
                }
                this.$store.dispatch('dispositivi/updateDispositivo', data1)
                // this.$emit('update-dev', data1)
                this.$emit('close-sidebar')
              }
            }).catch(e => e)
            // console.log('submited general')
          }
        })
        .catch(e => e)
    },
  },
}
</script>

<style>

</style>
