<template>
  <b-sidebar
    id="plu-devices-sidebar"
    ref="plu-devices-sidebar"
    v-model="show"
    sidebar-class="sidebar-40"
    bg-variant="white"
    backdrop-variant="dark"
    backdrop
    :no-close-on-backdrop="true"
    shadow
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ title }}
        </h5>
        <div>
          <!-- <feather-icon
            v-if="title === 'Modifica Collaboratore'"
            icon="TrashIcon"
            class="cursor-pointer"
            @click="$emit('remove-event', form); hide();"
          /> -->
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide()"
          />
        </div>
      </div>
      <b-row class="m-50">
        <b-col cols="12">
          <b-tabs
            pills
            variant="primary"
          >
            <b-tab title="Generale">
              <GeneralDeviceTab @close-sidebar="closeSidebar" />
            </b-tab>
            <b-tab
              v-if="$store.getters['dispositivi/getDispSelected'].id !== 0"
              title="Connessioni"
            >
              <ConnessionDeviceTab @close-sidebar="closeSidebar" />
            </b-tab>
            <!-- <b-tab
              title="Plu"
            >
              <PluDeviceTab />
            </b-tab>
            <b-tab title="Set Attributi">
              <SetAttributeDeviceTab />
            </b-tab>
            <b-tab title="Driver">
              <DriverDeviceTab />
            </b-tab>
            <b-tab title="Metodi">
              <MethodsDeviceTab />
            </b-tab> -->
          </b-tabs>
        </b-col>
      </b-row>
    </template>
  </b-sidebar>
</template>

<script>
/* import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations' */
import {
  BSidebar, BRow, BCol, BTabs, BTab,
} from 'bootstrap-vue'
import GeneralDeviceTab from './tabs/GeneralDeviceTab.vue'
import ConnessionDeviceTab from './tabs/ConnessionDeviceTab.vue'
// import vSelect from 'vue-select'

export default {
  components: {
    BSidebar,
    BRow,
    BCol,
    GeneralDeviceTab,
    ConnessionDeviceTab,
    BTabs,
    BTab,
  },
  data() {
    return {
      device: {},
      show: false,
      title: '',
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    setTitle(title) {
      this.title = title
    },
    setDevice(device) {
      this.device = { ...device }
    },
    openSidebar() {
      this.show = true
    },
    closeSidebar() {
      this.show = false
    },
  },
}
</script>

<style>

</style>
